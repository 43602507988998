import React, { useState } from 'react';
import styles from './PricingCalculatorCustomersSetup.module.css';
import { TextPrimary } from 'shopify/components/TextPrimary/TextPrimary';
import { map } from 'lodash';
import { ChevronRightIcon } from 'shopify/icons/ChevronRightIcon';
import { Slider } from 'shopify/components/Slider/Slider';

const values = [
    '3k', '5k', '8k', '10k', '15k', '20k', '25k', '50k', '100k', '200k', '300k', '400k', '500k', '600k', '700k'
];
export const PricingCalculatorCustomersSetup = () => {
    const [value, setValue] = useState(50);

    const onChange = (event) => {
        setValue(event.target.value);
    };
    return (
        <div className={styles.container}>
            <div className={styles.values}>
                {map(values, value =>
                    <div className={styles.value}>
                        <TextPrimary
                            text='Up to'
                            className={styles.text}
                        />
                        <TextPrimary
                            text={value} strong
                            className={styles.text}
                        />
                    </div>
                )}
                <div className={styles.value}>
                    <div className={styles.icon}>
                        <ChevronRightIcon/>
                    </div>
                    <TextPrimary
                        text='700k' strong
                        className={styles.text}
                    />
                </div>
            </div>
            <Slider
                value={value}
                onChange={onChange}
            />
        </div>
    )
}