import React from 'react';
import styles from './PricingCalculator.module.css';
import { RoundLayout } from 'shopify/layouts/RoundLayout/RoundLayout';
import { PricingCalculatorHeader } from 'shopify/modules/PricingCalculatorHeader/PricingCalculator';
import { PricingCalculatorFooter } from 'shopify/modules/PricingCalculatorFooter/PricingCalculatorFooter';
import { PricingCalculatorServices } from 'shopify/modules/PricingCalculatorServices/PricingCalculatorServices';
import {
    PricingCalculatorCommunications
} from 'shopify/modules/PricingCalculatorCommunications/PricingCalculatorCommunications';
import {
    PricingCalculatorCustomersSetup
} from 'shopify/modules/PricingCalculatorCustomersSetup/PricingCalculatorCustomersSetup';

export const PricingCalculator = () => {
    return (
        <RoundLayout className={styles.container}>
            <div className={styles.content}>
                <PricingCalculatorHeader/>
                <PricingCalculatorCustomersSetup/>
                <PricingCalculatorCommunications/>
                <PricingCalculatorServices/>
                <PricingCalculatorFooter/>
            </div>
        </RoundLayout>
    )
}