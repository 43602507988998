import React from 'react';
import styles from './PricingCalculatorCommunications.module.css';
import { CardLayout } from 'shopify/layouts/CardLayout/CardLayout';
import { AttentionText } from 'shopify/modules/AttentionText/AttentionText';
import { InputField } from 'shopify/modules/InputField/InputField';
import { TextPrimary } from 'shopify/components/TextPrimary/TextPrimary';
import { EmailIcon } from 'shopify/icons/EmailIcon';
import { MessageCloudIcon } from 'shopify/icons/MessageCloudIcon';
import { CheckGreen } from 'shopify/icons/CheckGreen';
import { NotebookIcon } from 'shopify/icons/NotebookIcon';
import { MobileIcon } from 'shopify/icons/MobileIcon';

export const PricingCalculatorCommunications = () => {
    return (
        <div className={styles.container}>
            <CardLayout className={styles.card}>
                <div className={styles.column}>
                    <AttentionText
                        icon={<EmailIcon/>}
                        text='Emails'
                        strong
                    />
                    <div className={styles.row}>
                        <InputField
                            id='email'
                            type='number'
                            label='Monthly email sends'
                            value=''
                            placeholder='250000'
                            onChange={() => {}}
                            warning={false}
                            required={false}
                            className={styles.input}
                            labelClassName={styles.label}
                        />
                        <TextPrimary
                            text='$1,249 / mo'
                            className={styles.price}
                            strong
                        />
                    </div>
                </div>
            </CardLayout>
            <CardLayout className={styles.card}>
                <div className={styles.column}>
                    <AttentionText
                        icon={<MessageCloudIcon/>}
                        text='SMS'
                        strong
                    />
                    <div className={styles.row}>
                        <InputField
                            id='sms'
                            type='number'
                            label='Monthly SMS sends'
                            value=''
                            placeholder='55000'
                            onChange={() => {}}
                            warning={false}
                            required={false}
                            className={styles.input}
                            labelClassName={styles.label}
                        />
                        <TextPrimary
                            text='$1,249 / mo'
                            className={styles.price}
                            strong
                        />
                    </div>
                </div>
            </CardLayout>
            <CardLayout className={styles.card}>
                <div className={styles.column}>
                    <div className={styles.row}>
                        <AttentionText
                            icon={<MobileIcon/>}
                            text='Mobile Push'
                            strong
                        />
                        <TextPrimary
                            text='On request'
                            className={styles.price}
                            strong
                        />
                    </div>
                    <div className={styles.row}>
                        <AttentionText
                            icon={<NotebookIcon/>}
                            text='Web Push'
                            strong
                        />
                        <AttentionText
                            icon={<CheckGreen/>}
                            text='Free'
                            strong
                        />
                    </div>
                </div>
            </CardLayout>
        </div>
    )
}