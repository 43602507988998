import React from 'react';
import styles from './PricingCalculatorFooter.module.css';
import { RoundLayout } from 'shopify/layouts/RoundLayout/RoundLayout';
import { TextSecondary } from 'shopify/components/TextSecondary/TextSecondary';
import { TextPrimary } from 'shopify/components/TextPrimary/TextPrimary';
import { Button } from 'shopify/components/Button/Button';
import { AttentionText } from 'shopify/modules/AttentionText/AttentionText';
import { CheckGreen } from 'shopify/icons/CheckGreen';

export const PricingCalculatorFooter = () => {
    return (
        <RoundLayout className={styles.container}>
            <div className={styles.content}>
                <div className={styles.column}>
                    <TextSecondary
                        text='Total price'
                    />
                    <TextPrimary
                        text='$1,249 / mo'
                        className={styles.price}
                        strong
                    />
                </div>

                <div className={styles.row}>
                    <AttentionText
                        icon={<CheckGreen/>}
                        text='2 weeks Free trial included'
                        strong
                    />
                    <Button
                        text='Start now'
                        onClick={() => {}}
                        className={styles.button}
                    />
                </div>
            </div>
        </RoundLayout>
    )
}