import React from 'react';
import styles from 'pages/Auth/Billing/Billing.module.css';
import { Logo } from 'shopify/components/Logo/Logo';
import { NavLink } from 'react-router-dom';
import { Button } from 'shopify/components/Button/Button';
import { Dropdown } from 'shopify/components/Dropdown/Dropdown';
import { RoundLayout } from 'shopify/layouts/RoundLayout/RoundLayout';
import { Title } from 'shopify/components/Title/Title';
import { TextSecondary } from 'shopify/components/TextSecondary/TextSecondary';
import { TextPrimary } from 'shopify/components/TextPrimary/TextPrimary';
import { InputField } from 'shopify/modules/InputField/InputField';
import { NamedCheckbox } from 'shopify/modules/NamedCheckbox/NamedCheckbox';
import { TextLink } from 'shopify/components/TextLink/TextLink';
import { PricingCalculator } from 'shopify/structures/PricingCalculator/PricingCalculator';

export const BillingPage = () => {
    return (
        <div className={styles.container}>
            <header>
                <Logo/>

                <nav>
                    {/*<NavLink to='/' className={styles.headerLink}>Use cases</NavLink>*/}
                    {/*<Dropdown*/}

                    {/*/>*/}
                    <Button
                        text='Get a demo'
                        onClick={() => {}}
                        className={styles.button}
                    />
                </nav>
            </header>

            <div className={styles.about}>
                <TextSecondary
                    text='Pricing'
                    className={styles.pageDescription}
                />
                <Title
                    text='Explore transparent pricing based on your needs'
                    className={styles.pageTitle}
                />
            </div>

            <PricingCalculator/>

            <RoundLayout className={styles.form}>
                <div className={styles.content}>
                    <div className={styles.title}>
                        <Title
                            text='Engage, Convert, Repeat, Retain your customers, longer'
                            className={styles.titleText}
                        />

                        <div className={styles.description}>
                            <TextPrimary
                                text='Schedule a call to discover how NeuCurrent can benefit you'
                            />
                            <TextSecondary
                                text='All fields are required'
                            />
                        </div>
                    </div>

                    <InputField
                        id='name'
                        type='name'
                        label='Name'
                        value=''
                        placeholder='Jim Wales'
                        onChange={() => {}}
                        warning={false}
                        required={true}
                    />
                    <InputField
                        id='email'
                        type='email'
                        label='Email'
                        value=''
                        placeholder='user@gmail.com'
                        onChange={() => {}}
                        warning={false}
                        required={true}
                    />
                    <InputField
                        id='phoneNumber'
                        type='number'
                        label='Phone number'
                        value=''
                        placeholder='Phone number'
                        onChange={() => {}}
                        warning={false}
                        required={true}
                    />
                    <InputField
                        id='companyWebsite'
                        type='text'
                        label='Company website'
                        value=''
                        placeholder='https://example.com'
                        onChange={() => {}}
                        warning={false}
                        required={true}
                    />

                    <NamedCheckbox
                        text='Sign up for NeuCurrent’s newsletters, product news and targeted advertising. Unsubscribe anytime'
                        className={styles.namedCheckbox}
                        onChange={() => {}}
                        isChecked={true}
                    />
                    <Button
                        text='Book a call'
                        onClick={() => {}}
                    />
                    <TextPrimary
                        text={
                            <>
                                By submitting this form, you agree to{' '}
                                <TextLink link="/" text="NeuCurrent Privacy Policy" />{' '}
                                and{' '}
                                <TextLink link="/" text="Terms of Service" />.
                            </>
                        }
                    />
                </div>
            </RoundLayout>
        </div>
    )
}