import React, { useState } from 'react';
import styles from './Dropdown.module.css';
import classNames from 'classnames/bind';
import { DownArrow } from 'shopify/icons/DownArrow';

const cx = classNames.bind(styles);
export const Dropdown = () => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <div className={styles.container}>
            <p onClick={toggleDropdown} className={styles.dropdown}>
                Our products
                <span className={cx(styles.arrow, { [styles.open]: isOpen })}>
                    <DownArrow/>
                </span>
            </p>

            {isOpen &&
                <ul className={styles.menu}>
                    <li className={styles.item}>Product 1</li>
                    <li className={styles.item}>Product 2</li>
                    <li className={styles.item}>Product 3</li>
                </ul>
            }
        </div>
    );
};