import React, { useState } from 'react';
import styles from './SignIn.module.css';
import { Logo } from 'shopify/components/Logo/Logo';
import { Title } from 'shopify/components/Title/Title';
import { TextSecondary } from 'shopify/components/TextSecondary/TextSecondary';
import { TextLink } from 'shopify/components/TextLink/TextLink';
import { InputField } from 'shopify/modules/InputField/InputField';
import { InputHiddenField } from 'shopify/modules/InputHiddenField/InputHiddenField';
import { Button } from 'shopify/components/Button/Button';
import { RoundLayout } from 'shopify/layouts/RoundLayout/RoundLayout';
import { useDispatch } from 'react-redux';
import { login } from 'store/actions/auth.action';
import { useTranslation } from 'react-i18next';
import { mapValues } from 'lodash';
import Validator from 'validatorjs';

const loginFormDataInitialState = {
    username: { value: '', error: '' },
    password: { value: '', error: '' },
};
const loginFormDataValidationRules = {
    username: 'required',
    password: 'required|min:8|max:24',
};
export const SignIn = () => {
    const dispatch = useDispatch();
    const { t: translator } = useTranslation();

    const [loginFormData, setLoginFormData] = useState(loginFormDataInitialState);

    const onChangeInput = event => {
        event.persist();

        setLoginFormData(prev => ({
            ...prev,
            [event.target.name]: {
                ...prev[event.target.name],
                value: event.target.value
            }
        }));
    };

    const onFocusClearErrors = event => {
        event.persist();

        setLoginFormData(prev => ({
            ...prev,
            [event.target.name]: {
                ...prev[event.target.name],
                error: ''
            }
        }));
    };

    const onClickLoginButton = () => {
        const userData = mapValues(loginFormData, input => input.value);

        const validation = new Validator(userData, loginFormDataValidationRules);

        if (validation.fails()) {
            return setLoginFormData(prev => mapValues(prev, (values, key) => ({
                ...values,
                error: validation.errors.first(key) || ''
            })))
        }

        dispatch(login({
            username: loginFormData.username.value,
            password: loginFormData.password.value
        }))

        .catch(error => {
            if (error.response) {
                const { message } = error.response.data;

                switch (message) {
                    case 'Unauthorized': setLoginFormData(prev => {
                        return {
                            ...prev,
                            password: { ...prev.password, error: translator('validationErrorMessages:Unauthorized')},
                        }
                    });
                        break;
                    case 'User does not exist': setLoginFormData(prev => {
                        return {
                            ...prev,
                            username: { ...prev.username, error: translator('validationErrorMessages:UserDoesNotExist') },
                        }
                    });
                        break;
                    default: console.error('[Auth Server]:', message);
                }
            }
        });
    };

    return (
        <RoundLayout>
            <React.Fragment>
                <Logo/>

                <div className={styles.title}>
                    <Title
                        text='Welcome back'
                    />

                    <div className={styles.description}>
                        <TextSecondary
                            text='Not registered yet?'
                        />
                        <TextLink
                            text='Create an account'
                            link='/sign-up'
                        />
                    </div>
                </div>

                <div className={styles.form}>
                    <InputField
                        id='username'
                        type='text'
                        label='Username'
                        value={loginFormData.username.value}
                        placeholder='...'
                        onChange={onChangeInput}
                        onFocus={onFocusClearErrors}
                        warning={!!loginFormData.username.error}
                        error={loginFormData.username.error}
                        required
                        autoFocus
                    />
                    <InputHiddenField
                        id='password'
                        type='password'
                        label='Password'
                        value={loginFormData.password.value}
                        placeholder='******'
                        onChange={onChangeInput}
                        onFocus={onFocusClearErrors}
                        warning={!!loginFormData.password.error}
                        error={loginFormData.password.error}
                        required
                    />

                    <div className={styles.row}>
                        {/*<NamedCheckbox*/}
                        {/*    text="Keep me logged in"*/}
                        {/*    onChange={() => {}}*/}
                        {/*    isChecked={true}*/}
                        {/*/>*/}
                        <TextLink
                            text='Forgot password?'
                            link='/request-password-recovery'
                        />
                    </div>

                    <Button
                        text='Get started'
                        onClick={onClickLoginButton}
                    />
                </div>
            </React.Fragment>
        </RoundLayout>
    )
}