import React from 'react';
import styles from './Slider.module.css';
import PropTypes from 'prop-types';

export const Slider = ({ value, onChange }) => {
    return (
        <div className={styles.container}>
            <input
                type='range'
                min='0'
                max='100'
                value={value}
                onChange={onChange}
                className={styles.slider}
                style={{
                    background: `linear-gradient(to right, #00C6A2 ${value}%, #F1F5F9 ${value}%)`
                }}
            />
        </div>
    )
};

Slider.propTypes = {
    value: PropTypes.number.isRequired,
    onChange: PropTypes.func.isRequired
};