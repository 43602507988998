import React from 'react';
import styles from './PricingCalculatorHeader.module.css';
import { TextSecondary } from 'shopify/components/TextSecondary/TextSecondary';
import { KeyValueText } from 'shopify/components/TextStrong/KeyValueText';
import { TextPrimary } from 'shopify/components/TextPrimary/TextPrimary';
import classNames from 'classnames/bind';

const cx = classNames.bind(styles);
export const PricingCalculatorHeader = () => {
    return (
        <div className={styles.container}>
            <div className={styles.column}>
                <TextSecondary
                    text='Pricing Calculator'
                />
                <KeyValueText
                    label='Number of customers:'
                    text='Up to 50k'
                />
            </div>
            <div className={cx(styles.column, styles.directionEnd)}>
                <TextSecondary
                    text='All Services price'
                />
                <TextPrimary
                    text='$1,249 / mo'
                    className={styles.price}
                    strong
                />
            </div>
        </div>
    )
}