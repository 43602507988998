import React from 'react';
import styles from './CardLayout.module.css';
import classNames from 'classnames/bind';
import PropTypes from 'prop-types';

const cx = classNames.bind(styles);

export const CardLayout = ({ children, className }) => {
    return (
        <div className={cx(styles.container, className)}>
            {children}
        </div>
    )
};

CardLayout.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string
};
CardLayout.defaultProps = {
    className: ''
};