import React, { useState } from 'react';
import styles from './SignUp.module.css';
import { Logo } from 'shopify/components/Logo/Logo';
import { Title } from 'shopify/components/Title/Title';
import { TextLink } from 'shopify/components/TextLink/TextLink';
import { TextSecondary } from 'shopify/components/TextSecondary/TextSecondary';
import { Button } from 'shopify/components/Button/Button';
import { TextPrimary } from 'shopify/components/TextPrimary/TextPrimary';
import { Requirements } from 'shopify/modules/Requirements/Requirements';
import { StringStrengthMeter } from 'shopify/modules/StringStrengthMeter/StringStrengthMeter';
import { InputField } from 'shopify/modules/InputField/InputField';
import { InputHiddenField } from 'shopify/modules/InputHiddenField/InputHiddenField';
import { NamedCheckbox } from 'shopify/modules/NamedCheckbox/NamedCheckbox';
import { RoundLayout } from 'shopify/layouts/RoundLayout/RoundLayout';
import { mapValues } from 'lodash';
import Validator from 'validatorjs';
import { StringUse } from 'lib/StringUse';
import { useLocation, useNavigate } from 'react-router-dom';
import { getSignUp } from 'store/actions/auth.action';
import { useDispatch } from 'react-redux';
import { useToast } from 'hooks/useToast';

const registerFormDataValidationRules = {
    email: 'required',
    password: 'required|min:8|max:24|regex:/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\*])/',
    confirmPassword: 'required|min:8|max:24|same:password',
    companyName: 'required|min:1|max:32',
    companyWebsite: 'required|min:1|max:46',
    phoneNumber: 'required|regex:/^\\+?[1-9]\\d{1,14}$/',
};
export const SignUp = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const { addToast } = useToast();

    const params = new URLSearchParams(location.search);

    const [registerFormData, setRegisterFormData] = useState({
        email: { value: params.get('email') || '', error: '' },
        password: { value: '', error: '' },
        confirmPassword: { value: '', error: '' },
        companyName: { value: params.get('company_name') || '', error: '' },
        companyWebsite: { value: params.get('shop') || '', error: '' },
        phoneNumber: { value: '', error: '' },
    });
    const [isUserWantDemo, setIsUserWantDemo] = useState(false);

    const [strength, setStrength] = useState('default');

    const onChangeInput = event => {
        event.persist();

        setRegisterFormData(prev => ({
            ...prev,
            [event.target.name]: {
                ...prev[event.target.name],
                value: event.target.value
            }
        }));

        if (event.target.name === 'password') {
            setStrength(StringUse.calculatePasswordStrength(event.target.value));
        }
    };

    const onFocusClearErrors = event => {
        event.persist();

        setRegisterFormData(prev => ({
            ...prev,
            [event.target.name]: {
                ...prev[event.target.name],
                error: ''
            }
        }));
    };

    const redirectAfterSignUp = data => {
        navigate(`/verification?user_id=${data.user_id}&username=${data.username}`);
    };
    const onFailureSignUp = ({ data }) => {
        addToast(data.message, 'warning')
    };
    const onClickRegisterButton = () => {
        const userData = mapValues(registerFormData, input => input.value);

        const validation = new Validator(userData, registerFormDataValidationRules);

        if (validation.fails()) {
            return setRegisterFormData(prev => mapValues(prev, (values, key) => ({
                ...values,
                error: validation.errors.first(key) || ''
            })))
        }

        dispatch(getSignUp({
            email: registerFormData.email.value,
            password: registerFormData.password.value,
            phone: registerFormData.phoneNumber.value,
            company_name: registerFormData.companyName.value,
            shop: registerFormData.companyWebsite.value,
            want_demo: isUserWantDemo
        },
            redirectAfterSignUp,
            onFailureSignUp
        ));
    };

    const onChangeUserWantDemo = () => setIsUserWantDemo(prev => !prev);

    return (
        <RoundLayout>
            <React.Fragment>
                <Logo/>

                <div className={styles.title}>
                    <Title
                        text='Sign up for Free'
                    />

                    <div className={styles.description}>
                        <TextSecondary
                            text='Already have a NeuCurrent account?'
                        />
                        <TextLink
                            text='Log in'
                            link='/'
                        />
                    </div>
                </div>

                <div className={styles.form}>
                    <InputField
                        id='email'
                        type='email'
                        label='Email'
                        value={registerFormData.email.value}
                        placeholder='user@gmail.com'
                        onChange={onChangeInput}
                        onFocus={onFocusClearErrors}
                        warning={!!registerFormData.email.error}
                        error={registerFormData.email.error}
                        required
                        autoFocus
                    />
                    <InputHiddenField
                        id='password'
                        type='password'
                        label='Password'
                        value={registerFormData.password.value}
                        placeholder='******'
                        onChange={onChangeInput}
                        onFocus={onFocusClearErrors}
                        warning={!!registerFormData.password.error}
                        error={registerFormData.password.error}
                        required
                    />
                    <StringStrengthMeter
                        strength={strength}
                    />
                    <div className={styles.requirements}>
                        <Requirements
                            text="Lowercase and Uppercase letters"
                            done={/^(?=.*[a-z])(?=.*[A-Z])/.test(registerFormData.password.value)}
                        />
                        <Requirements
                            text="Numbers"
                            done={/^(?=.*[0-9])/.test(registerFormData.password.value)}
                        />
                        <Requirements
                            text="Special characters"
                            done={/^(?=.*[!@#$%^&*])/.test(registerFormData.password.value)}
                        />
                    </div>
                    <InputHiddenField
                        id='confirmPassword'
                        type='password'
                        label='Confirm password'
                        value={registerFormData.confirmPassword.value}
                        placeholder='******'
                        onChange={onChangeInput}
                        onFocus={onFocusClearErrors}
                        warning={!!registerFormData.confirmPassword.error}
                        error={registerFormData.confirmPassword.error}
                        required
                    />
                    <InputField
                        id='companyName'
                        type='text'
                        label='Company name'
                        value={registerFormData.companyName.value}
                        placeholder='Company name'
                        onChange={onChangeInput}
                        onFocus={onFocusClearErrors}
                        warning={!!registerFormData.companyName.error}
                        error={registerFormData.companyName.error}
                        required
                    />
                    <InputField
                        id='companyWebsite'
                        type='text'
                        label='Company website'
                        value={registerFormData.companyWebsite.value}
                        placeholder='shop.myshopify.com'
                        onChange={onChangeInput}
                        onFocus={onFocusClearErrors}
                        warning={!!registerFormData.companyWebsite.error}
                        error={registerFormData.companyWebsite.error}
                        required
                    />
                    <InputField
                        id='phoneNumber'
                        type='number'
                        label='Phone number'
                        value={registerFormData.phoneNumber.value}
                        placeholder='+1 234 67 67 890'
                        onChange={onChangeInput}
                        onFocus={onFocusClearErrors}
                        warning={!!registerFormData.phoneNumber.error}
                        error={registerFormData.phoneNumber.error}
                        required
                    />
                    <NamedCheckbox
                        text="I would like a demo/consultation"
                        onChange={onChangeUserWantDemo}
                        isChecked={isUserWantDemo}
                    />
                    <TextPrimary
                        text={
                            <>
                                By pressing Get started, you agree to{' '}
                                <a target='_blank' href="https://www.iubenda.com/privacy-policy/11363900/legal" className={styles.link} rel="noreferrer">NeuCurrent Privacy Policy</a>{' '}
                                and{' '}
                                <a target='_blank' href="https://www.iubenda.com/terms-and-conditions/11363900" className={styles.link} rel="noreferrer">Terms of Service</a>.
                            </>
                        }
                    />
                    <Button
                        text='Get started'
                        onClick={onClickRegisterButton}
                    />
                </div>
            </React.Fragment>
        </RoundLayout>
    )
}